import React, { useCallback, useEffect, useState } from 'react';
import { Box, Tab, Tabs } from "@mui/material";
import './App.css';
import { usePublicClientsList, useWalletList } from './hooks';
import { SettingsView, ListingView, Logs } from './views';
import { useLogs } from './hooks/useLogs';
import { AdvancedTokenInfo } from './views/Listing';
import { ERC721Abi } from './abis';
import bn from "bignumber.js";
import { BLUR_OPERATORS, OPENSEA_OPERATORS } from './settings';

const TABS = [
  "Settings",
  "Listing",
  "Logs",
]

function App() {
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [activeChainId, setActiveChainId] = useState(1);
  const [walletCount, setWalletCount] = useState<number>(0);
  const pubClientsMap = usePublicClientsList();
  const { accounts, createAccounts } = useWalletList();
  const [tokenAddress, setTokenAddress] = useState<string>();
  const [tokenTempAddress, setTokenTempAddress] = useState<string>();
  const [tokensInfo, setTokensInfo] = useState<AdvancedTokenInfo[]>([]);
  const [seedPhrase, setSeedPhrase] = useState("");
  const [approvalsOs, setApprovalsOs] = useState<boolean[]>([]);
  const [approvalsBlur, setApprovalsBlur] = useState<boolean[]>([]);
  const [selectedAccountsSet, setSelectedAccountsSet] = useState<Set<string>>(new Set())
  const [selectedTokenInfosSet, setSelectedTokenInfosSet] = useState<Set<string>>(new Set());
  const [allPricesValue, setAllPricesValue] = useState<string>();
  const [tokenPriceInputs, setTokenPriceInputs] = useState<Record<string, string>>({});
  const [checksOpensea, setChecksOpensea] = useState<Set<string>>(new Set());
  const [checksBlur, setChecksBlur] = useState<Set<string>>(new Set());
  const [infoDurations, setInfoDurations] = useState<Record<string, number>>({});

  const [balances, setBalances] = useState<number[]>([]);
  const [tokenBalances, setTokenBalances] = useState<number[]>([]);
  const [isLoadingBalances, setIsLoadingBalances] = useState<boolean>(false);

  const [tokenName, setTokenName] = useState("");

  const { logs, addLog } = useLogs();

  const changeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  }

  const updateAccountsMeta = useCallback(async () => {
    setIsLoadingBalances(true);
    const client = pubClientsMap.get(activeChainId);

    if(!client) {
        setIsLoadingBalances(false);
        return;
    }

    const balancesReq = [];

    for(const account of accounts) {
        // @ts-ignore
        const balanceReq = client.getBalance({ address: account.address as `0x${string}` });

        balancesReq.push(balanceReq);
    }

    const decimals = bn(10).pow(18);
    const balances_ = await Promise
        .all(balancesReq)
        .then((balancesRaw) => balancesRaw.map((blnc: any) => bn(blnc.toString()).div(decimals).toNumber().toFixed(6)))
        .catch((err) => [] as string[]);

    // @ts-ignore
    setBalances(balances_);

    if(tokenAddress) {
        const nftBalancesReqs = [];

        for(const account of accounts) {
            // @ts-ignore
            const req = client.readContract({
                address: tokenAddress as `0x${string}`,
                abi: ERC721Abi,
                functionName: "balanceOf",
                args: [account.address]
            });

            nftBalancesReqs.push(req);
        }

        const tokenBalances_ = await Promise.all(nftBalancesReqs).then((res) => res.map((balance_: any) => Number(balance_))).catch((err) => []);

        setTokenBalances(tokenBalances_);

        if(OPENSEA_OPERATORS[activeChainId]) {
            const approvalsReqs = [];

            for(const account of accounts) {
                // @ts-ignore
                const approvalReq = client.readContract({
                    address: tokenAddress as `0x${string}`,
                    abi: ERC721Abi,
                    functionName: "isApprovedForAll",
                    args: [account.address, OPENSEA_OPERATORS[activeChainId]],
                });

                approvalsReqs.push(approvalReq);
            }

            const approvals = await Promise.all(approvalsReqs).catch(() => []);

            setApprovalsOs(approvals as boolean[]);
        }

        if(BLUR_OPERATORS[activeChainId]) {
            const approvalsReqs = [];

            for(const account of accounts) {
                // @ts-ignore
                const approvalReq = client.readContract({
                    address: tokenAddress as `0x${string}`,
                    abi: ERC721Abi,
                    functionName: "isApprovedForAll",
                    args: [account.address, BLUR_OPERATORS[activeChainId]],
                });

                approvalsReqs.push(approvalReq);
            }

            const approvals = await Promise.all(approvalsReqs).catch(() => []);

            setApprovalsBlur(approvals as boolean[]);
        }
    }

    setIsLoadingBalances(false);
  }, [accounts, activeChainId, pubClientsMap, tokenAddress]);

  useEffect(() => {
    updateAccountsMeta();
  }, [updateAccountsMeta]);
  
  return (
    <div className="App">
      <Box m={2}>
        <Tabs value={activeTab} onChange={changeTab}>
          {
            TABS.map((tab) => (
              <Tab
                key={tab}
                label={tab}
                value={tab}
              />
            ))
          }
        </Tabs>
        {
          activeTab === "Settings" && (
            <SettingsView
              accounts={accounts}
              activeChainId={activeChainId}
              setActiveChainId={setActiveChainId}
              walletCount={walletCount}
              setWalletCount={setWalletCount}
              createAccounts={createAccounts}
              addLog={addLog}
              seedPhrase={seedPhrase}
              setSeedPhrase={setSeedPhrase}
            />
          )
        }
        {
          activeTab === "Listing" && (
            <ListingView
              activeChainId={activeChainId}
              pubClientsMap={pubClientsMap}
              accounts={accounts}
              addLog={addLog}
              tokenAddress={tokenAddress}
              setTokenAddress={setTokenAddress}
              tokenTempAddress={tokenTempAddress}
              setTokenTempAddress={setTokenTempAddress}
              balances={balances}
              tokenBalances={tokenBalances}
              tokensInfo={tokensInfo}
              setTokensInfo={setTokensInfo}
              approvalsOs={approvalsOs}
              approvalsBlur={approvalsBlur}
              updateAccountsMeta={updateAccountsMeta}
              selectedAccountsSet={selectedAccountsSet}
              setSelectedAccountsSet={setSelectedAccountsSet}
              selectedTokenInfosSet={selectedTokenInfosSet}
              setSelectedTokenInfosSet={setSelectedTokenInfosSet}
              allPricesValue={allPricesValue}
              setAllPricesValue={setAllPricesValue}
              tokenPriceInputs={tokenPriceInputs}
              setTokenPriceInputs={setTokenPriceInputs}
              checksOpensea={checksOpensea}
              setChecksOpensea={setChecksOpensea}
              checksBlur={checksBlur}
              setChecksBlur={setChecksBlur}
              infoDurations={infoDurations}
              setInfoDurations={setInfoDurations}
              isLoadingBalances={isLoadingBalances}
              tokenName={tokenName}
              setTokenName={setTokenName}
            />
          )
        }
        {
          activeTab === "Logs" && (
            <Logs logItems={logs} />
          )
        }
      </Box>
    </div>
  );
}

export default App;
